import React from 'react';

interface TitleProps {
  text: string;
}

const Title: React.FC<TitleProps> = ({ text }) => {
  return <h1 className='text-white text-3xl md:text-5xl'>{text}</h1>;
};

export default Title;
