"use client";
import { useTranslations } from 'next-intl';
import Title from '@/components/ui/Title';
import Lottie from "lottie-react";
import notFound from '@/components/animations/not-found.json';
import { ArrowRight } from 'lucide-react';
import Link from 'next/link';

export default function NotFoundPage() {
  const t = useTranslations('NotFoundPage');

  return (
    <div className='bg-white colmena-rounded-t overflow-hidden relative pb-24'>
      <div className="bg-brand-blue-reg rounded-b-3xl md:rounded-b-4xl min-h-screen w-full mx-auto colmena-p flex flex-col gap-y-8 items-center justify-center text-center text-white">
        <div className="h-32 w-32 md:h-56 md:w-56 mx-auto">
          <Lottie animationData={notFound} loop={true} className='object-contain scale-150' />
        </div>
        <Title text={t('title')} />
        <p>{t('description')}</p>
        <Link href={'/'} className="mt-4 w-full rounded bg-brand-blue-dark px-9 py-4 text-lg text-white transition-colors hover:bg-brand-blue-mid md:w-fit mx-auto">
          Regresar al Inicio <ArrowRight className="inline" />
        </Link>
      </div>
    </div>
  );
}